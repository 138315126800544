import { createClient } from "contentful";

const useContentful = () => {
  const client = createClient({
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  });

  const getTrainings = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "training",
        select: "fields",
      });

      const sanitizedEntries = entries.items.map((item) => {
        return {
          ...item.fields,
        };
      });

      return sanitizedEntries;
    } catch (error) {
      console.error("Error fetching trainings:", error);
    }
  };

  const getTrainingById = async (order) => {
    try {
      const entry = await client.getEntry(order, {
        content_type: "training",
      });

      return {
        ...entry.fields,
      };
    } catch (error) {
      console.error(`Error fetching training with ID ${order}:`, error);
    }
  };

  return { getTrainings, getTrainingById };
};

export default useContentful;
