import React, { useState, useRef, useEffect } from "react";
import gsap from "gsap";
import "./Testimonials.css";
import Kobieta1 from "../../images/kobieta1.jpeg";
import Kobieta2 from "../../images/kobieta2.jpeg";
import Kobieta3 from "../../images/kobieta3.jpeg";
import Kobieta4 from "../../images/kobieta4.webp";
import Kobieta5 from "../../images/kobieta5.webp";
import Kobieta6 from "../../images/kobieta6.webp";

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(2);
  const [direction, setDirection] = useState("left");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);
  const testimonialsRef = useRef(null);
  const testimonials = [
    {
      name: "Ania",
      image: Kobieta1,
      text: "Jestem w 3 miesiącu planu treningowego od Igi i widzę znaczącą poprawę w stylu życia, czuję się lepiej a moje mięśnie pośladkowe zaczynają się fajnie zaokrąglać",
    },
    {
      name: "Julka",
      image: Kobieta2,
      text: "W końcu udało mi się zbudować pośladki i płaski brzuch. Dzięki ćwiczeniom ułożonym przez Igę odzyskałam wiarę w siebie. Polecam każdemu, kto chce poprawić swoją formę i zdrowie",
    },
    {
      name: "Kasia",
      image: Kobieta3,
      text: "Super treningowy plan od Igi! Plan jest prosty i czytelny. Teraz mam super energię, polecam",
    },
    {
      name: "Ewa",
      image: Kobieta4,
      text: "Dzięki indywidualnemu podejściu Igi poczułam, że moje potrzeby są naprawdę ważne. Każdy trening był dokładnie dostosowany do mojego poziomu, co przyniosło niesamowite rezultaty!",
    },
    {
      name: "Marta",
      image: Kobieta5,
      text: "Z Iga osiągnęłam swoje cele szybciej niż myślałam! Jej motywacja i profesjonalizm zrobiły różnicę. Polecam ją każdemu, kto szuka nie tylko trenera, ale i inspiracji do zmian.",
    },
    {
      name: "Ola",
      image: Kobieta6,
      text: "Doświadczenie z Iga zmieniło moje podejście do ćwiczeń i zdrowego odżywiania. Dzięki jej wsparciu i wiedzy nie tylko poprawiłam swoją kondycję, ale też nauczyłam się, jak cieszyć się zdrowym stylem życia.",
    },
  ];

  const handleMouseEnter = (e) => {
    gsap.to(e.currentTarget, {
      scale: 1.05,
      ease: "power1.out",
      duration: 0.3,
    });
  };

  const handleMouseLeave = (e) => {
    gsap.to(e.currentTarget, { scale: 1, ease: "power1.inOut", duration: 0.3 });
  };

  const visibleTestimonials = 3;

  useEffect(() => {
    if (window.innerWidth > 1024) {
      const testimonialsNodes = testimonialsRef.current.children;
      gsap.set(testimonialsNodes, {
        autoAlpha: 0,
        x: direction === "left" ? 100 : -100,
      });

      gsap.to(testimonialsNodes, {
        autoAlpha: 1,
        x: 0,
        stagger: 0.1,
        duration: 0.5,
        ease: "power1.out",
        overwrite: true,
      });
    }
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeIndex, direction]);

  const handlePrevClick = () => {
    if (window.innerWidth > 1024) {
      if (activeIndex > 0) {
        setActiveIndex(activeIndex - 1);
        setDirection("left");
      }
    }
  };

  const handleNextClick = () => {
    if (window.innerWidth > 1024) {
      if (activeIndex < testimonials.length - visibleTestimonials) {
        setActiveIndex(activeIndex + 1);
        setDirection("right");
      }
    }
  };

  const isActive = (index) => {
    return index >= activeIndex && index < activeIndex + visibleTestimonials;
  };

  const isMiddle = (index) => {
    return index === activeIndex + 1;
  };

  return (
    <div className="testimonials">
      <div className="testimonials-container">
        <div className="testimonials-header">
          <h1>Co mówią o mnie klienci</h1>
          <p>Wasze sukcesy, moja duma.</p>
        </div>
        {!isMobileView && (
          <div className="testimonials-ent-container">
            <button
              onClick={handlePrevClick}
              className={
                activeIndex === 0
                  ? "before-testimonial opacity-20"
                  : "before-testimonial"
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19.5 12.9999L7.914 12.9999L12.414 17.4999L11 18.9139L4.086 11.9999L11 5.08594L12.414 6.49994L7.914 10.9999L19.5 10.9999L19.5 12.9999Z"
                  fill="black"
                />
              </svg>
            </button>
            <div className="testimonials-entries" ref={testimonialsRef}>
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className={`single-testimonial ${
                    isActive(index) && isMiddle(index)
                      ? "active middle"
                      : isActive(index) && !isMiddle(index)
                      ? "active"
                      : ""
                  }`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="signle-testimonial-quotation"></div>
                  <div className="single-testimonial-image">
                    <img src={testimonial.image} alt={testimonial.name} />
                    <h2
                      className={
                        isMiddle(index)
                          ? "testimonial-name middle-box"
                          : "testimonial-name"
                      }
                    >
                      {testimonial.name}
                    </h2>
                  </div>
                  <div className="testimonial-text-container">
                    <p className="testimonial-text">{testimonial.text}</p>
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={handleNextClick}
              className={
                activeIndex === testimonials.length - 3
                  ? "after-testimonial opacity-20"
                  : "after-testimonial"
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4.49994 11.0001L16.0859 11.0001L11.5859 6.50006L12.9999 5.08606L19.9139 12.0001L12.9999 18.9141L11.5859 17.5001L16.0859 13.0001L4.49994 13.0001L4.49994 11.0001Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
        )}

        {/* Telefony */}
        {isMobileView && (
          <div className="testimonials-entries" ref={testimonialsRef}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="single-testimonial active">
                <div className="signle-testimonial-quotation"></div>
                <div className="single-testimonial-image">
                  <img src={testimonial.image} alt={testimonial.name} />
                  <h2 className="testimonial-name">{testimonial.name}</h2>
                </div>
                <div className="testimonial-text-container">
                  <p className="testimonial-text">{testimonial.text}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
