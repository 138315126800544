import React from "react";
import "./ProductContainer.css";
import ShopifyBuyButton from "./ShopifyBuyButton";

export default function ProductContainer({ productId }) {
  return (
    <div className="shopify-container">
      <ShopifyBuyButton productId={productId} />
    </div>
  );
}