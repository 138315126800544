import React from "react";
import TermsOfService from "../components/Law/TermsOfService";
import Navbar from "../components/Navbar/Navbar";

export default function TermsPage() {
  return (
    <div>
      <Navbar goBackButton={true} />
      <TermsOfService />
    </div>
  );
}
