import React from "react";
import "./Law.css";

const PrivacyPolicy = () => {
  return (
    <div className="law-container">
      <div className="law-container-text">
        <h1>Polityka Prywatności</h1>
        <section>
          <h2>1. Postanowienia ogólne</h2>
          <p>
            1.1. Niniejsza Polityka Prywatności określa zasady przetwarzania i
            ochrony danych osobowych użytkowników korzystających ze strony
            internetowej Trenera Personalnego Online.
          </p>
          <p>
            1.2. Administratorem danych osobowych jest Trener Personalny Online,
            z siedzibą podaną na stronie internetowej.
          </p>
        </section>

        <section>
          <h2>2. Rodzaje zbieranych danych osobowych</h2>
          <p>
            2.1. Podczas korzystania ze strony internetowej mogą być zbierane
            następujące dane osobowe: a. Dane identyfikacyjne (np. imię,
            nazwisko, adres e-mail, numer telefonu, adres rozliczeniowy) podane
            dobrowolnie podczas rejestracji lub zakupów. b. Dane dotyczące
            płatności, które są zbierane wyłącznie w celu dokonania transakcji.
            c. Informacje zbierane za pomocą plików cookies i podobnych
            technologii, służące optymalizacji korzystania ze strony
            internetowej (dotyczące zakupów, w tym przedmioty, które
            przeglądasz, dodajesz do koszyka lub umieszczasz na liście życzeń).
            d. Informacje dotyczące obsługi klienta, w tym informacje, które
            wybierasz do uwzględnienia w komunikatach z nami, na przykład
            podczas wysyłania wiadomości.
          </p>
        </section>

        <section>
          <h2>3. Cel przetwarzania danych</h2>
          <p>
            3.1. Dane osobowe użytkowników są przetwarzane w celu: a. Realizacji
            zamówień i świadczenia usług Trenera Personalnego Online. b.
            Kontaktu z użytkownikami w celu udzielenia informacji dotyczących
            zakupionych usług. c. Poprawy jakości świadczonych usług oraz
            dostosowania oferty do potrzeb użytkowników. d. Utrzymania i
            zapewnienia bezpieczeństwa strony internetowej.
          </p>
        </section>

        <section>
          <h2>4. Pliki cookies</h2>
          <p>
            4.1. Strona internetowa korzysta z plików cookies w celu ułatwienia
            korzystania z serwisu oraz zbierania informacji statystycznych o
            ruchu na stronie.
          </p>
          <p>
            4.2. Użytkownik ma możliwość zarządzania plikami cookies za pomocą
            ustawień przeglądarki internetowej. Może również wyłączyć obsługę
            plików cookies, jednak może to wpłynąć na działanie niektórych
            funkcji strony.
          </p>
        </section>

        <section>
          <h2>5. Udostępnianie danych osobowych</h2>
          <p>
            5.1. Dane osobowe użytkowników nie są udostępniane osobom trzecim,
            chyba że jest to niezbędne do świadczenia usług (np. współpraca z
            firmą obsługującą płatności).
          </p>
        </section>

        <section>
          <h2>6. Bezpieczeństwo danych</h2>
          <p>
            6.1. Trener Personalny Online podejmuje wszelkie niezbędne środki
            techniczne i organizacyjne w celu ochrony danych osobowych przed
            nieuprawnionym dostępem, utratą, uszkodzeniem lub ujawnieniem.
          </p>
        </section>

        <section>
          <h2>7. Prawa użytkowników</h2>
          <p>
            7.1. Użytkownik ma prawo dostępu do swoich danych osobowych, ich
            poprawiania, usunięcia lub ograniczenia przetwarzania. Może także
            wycofać zgodę na przetwarzanie danych w dowolnym momencie.
          </p>
        </section>

        <section>
          <h2>8. Kontakt</h2>
          <p>
            8.1. W sprawach związanych z ochroną danych osobowych użytkownicy
            mogą skontaktować się z Administratorem za pomocą danych
            kontaktowych podanych na stronie internetowej.
          </p>
          <p>
            Dane firmy: SICHNELL sp. z o.o. Tomasz Ostrowski Ul. Jasna 2 57-215
            Srebrna Góra NIP: 7011008233 Regon: 387703813
          </p>
        </section>

        <section>
          <h2>9. Zmiany w Polityce Prywatności</h2>
          <p>
            9.1. Polityka Prywatności może być okresowo aktualizowana. Wszelkie
            zmiany zostaną opublikowane na stronie internetowej.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
