import React from "react";
import Navbar from "../components/Navbar/Navbar";
import { useParams } from "react-router-dom";
import ProductContainer from "../components/Product/ProductContainer";

export default function Product() {
  let { productId } = useParams();

  return (
    <div>
      <Navbar goBackButton={true} />
      <ProductContainer productId={productId} />
    </div>
  );
}