import React from "react";
import "./Hero.css";
import IgaPhoto from "../../images/iga-bez-tla.webp";

export default function Hero({ scrollToOffer, scrollToContact }) {
  return (
    <div className="hero">
      <div className="hero-content">
        <h1 className="hero-title white-shadow">
          OSIĄGNIJ WYMARZONĄ SYLWETKĘ NA DŁUGIE LATA!
        </h1>
        <div className="hero-buttons">
          <div className="button" onClick={scrollToOffer}>
            Zacznij już dziś!
          </div>
        </div>
      </div>
      <img src={IgaPhoto} width="500" height="500" alt="Iga Sokołowska" />
    </div>
  );
}
