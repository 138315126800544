import React from "react";
import "./Footer.css";
import Instagram from "../../icons/instagram.svg";
import Tiktok from "../../icons/tiktok.svg";
import Youtube from "../../icons/youtube.svg";

export default function Footer() {
  return (
    <div>
      <footer className="footer-container">
        <div className="socials">
          <h1>Znajdź mnie na:</h1>
          {/* <a href="#about">
            <img src={Facebook} alt="" />{" "}
          </a> */}
          <a href="https://www.instagram.com/iga_sokolowska/">
            <img src={Instagram} alt="" />
          </a>
          <a href="https://www.tiktok.com/@iga_sokolowska">
            <img src={Tiktok} alt="" />
          </a>
          <a href="https://www.youtube.com/channel/UCnT5j1X0jr_sqkfsy9W7R0g">
            <img src={Youtube} alt="" />
          </a>
        </div>
        <div className="law">
          <a href="/privacy-policy">polityka prywatności</a>
          <a href="/terms-of-service">regulamin</a>
          {/* <a href="/terms-of-service">cookies</a> */}
        </div>
      </footer>
    </div>
  );
}
