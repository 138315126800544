import React, { useEffect, useState, forwardRef } from "react";
import "./Offer.css";
import useContentful from "../../hooks/useContentful";
import Check from "../../icons/check.svg";
import Cross from "../../icons/cross.svg";

//import Form from "../Form/Form";
//import ShopifyBuyButton from "../Product/ShopifyBuyButton";

const Offer = forwardRef((props, ref) => {
  // const [formVisible, setFormVisible] = useState(false);

  // const handleButtonClick = () => {
  //   setFormVisible(true);
  // };

  const [trainings, setTrainings] = useState([]);
  const { getTrainings } = useContentful();

  useEffect(() => {
    getTrainings().then((response) => {
      const sortedTrainings = response.sort((a, b) => a.order - b.order);

      const updatedTrainings = sortedTrainings.map((training) => {
        const storedPriceKey = `lowestPrice-${training.shopifyId}`;
        const storedPrice = localStorage.getItem(storedPriceKey);
        let lowestPrice = storedPrice
          ? parseFloat(storedPrice)
          : training.discountedPrice;

        if (training.discountedPrice < lowestPrice) {
          lowestPrice = training.discountedPrice;
          localStorage.setItem(storedPriceKey, lowestPrice.toString());
        }

        return { ...training, lowestPrice };
      });

      setTrainings(updatedTrainings);
    });
  }, [getTrainings]);

  return (
    <div className="offers">
      <div className="offers-header-text">
        <h1>Wybierz plan dla siebie!</h1>
      </div>
      <div ref={ref} className="offers-content">
        {trainings.map((training, index) => (
          <div key={training.shopifyId} className="offers-content-offer">
            {training.label !== undefined && (
              <div className="offer-over-tag">
                <h1>{training.label}</h1>
              </div>
            )}
            <div className={`single-offer ${index === 0 ? "bestseller" : ""}`}>
              <h2>{training.title}</h2>
              <p>{training.description}</p>
              {window.innerWidth > 768 && (
                <>
                  <div className="offer-price-smallest-price-container">
                    {training.price !== undefined ? (
                      <div
                        className={`offer-price-container crossed ${
                          index === 0 ? "bestseller-price" : ""
                        }`}
                      >
                        <div
                          className={`offer-price-container-inner ${
                            index === 0 ? "bestseller-price" : ""
                          }`}
                        >
                          <span className="offer-currency price">zł</span>
                          <span className="offer-amount price">
                            {training.price.toString().split(".")[0]}
                          </span>
                          <span className="offer-decimals price">
                            {training.price.toString().split(".").length > 1
                              ? "."
                              : ""}
                            {training.price.toString().split(".")[1]}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div style={{ marginBottom: "40%" }}></div>
                    )}
                    <div className="offer-smallest-price">
                      <span>{training.lowestPrice}zł · Najniższa cena z</span>
                      <span>30 dni przed obniżką</span>
                    </div>
                  </div>
                  <div
                    className={`offer-price-container ${
                      index === 0 ? "bestseller-price" : ""
                    }`}
                  >
                    <span className="offer-currency">zł</span>
                    <span className="offer-amount">
                      {training.discountedPrice.toString().split(".")[0]}
                    </span>
                    <span className="offer-decimals">
                      {training.discountedPrice.toString().split(".").length > 1
                        ? "."
                        : ""}
                      {training.discountedPrice.toString().split(".")[1]}
                    </span>
                  </div>
                </>
              )}
              {window.innerWidth <= 768 && (
                <>
                  <div className="offer-price-smallest-price-container">
                    <div className="offer-smallest-price">
                      <span>{training.lowestPrice}zł · Najniższa cena z</span>
                      <span>30 dni przed obniżką</span>
                    </div>
                  </div>
                  <div></div>
                  <div
                    className={`offer-price-container ${
                      index === 0 ? "bestseller-price" : ""
                    }`}
                    style={
                      training.price === undefined ? { display: "block" } : {}
                    }
                  >
                    <span className="offer-currency">zł</span>
                    <span className="offer-amount">
                      {training.discountedPrice.toString().split(".")[0]}
                    </span>
                    <span className="offer-decimals">
                      {training.discountedPrice.toString().split(".").length > 1
                        ? "."
                        : ""}
                      {training.discountedPrice.toString().split(".")[1]}
                    </span>
                    {training.price !== undefined ? (
                      <div
                        className={`offer-price-container crossed ${
                          index === 0 ? "bestseller-price" : ""
                        }`}
                      >
                        <div
                          className={`offer-price-container-inner ${
                            index === 0 ? "bestseller-price" : ""
                          }`}
                        >
                          <span className="offer-currency price">zł</span>
                          <span className="offer-amount price">
                            {training.price.toString().split(".")[0]}
                          </span>
                          <span className="offer-decimals price">
                            {training.price.toString().split(".").length > 1
                              ? "."
                              : ""}
                            {training.price.toString().split(".")[1]}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </>
              )}
              <hr />
              <ul>
                {training.pros &&
                  training.pros.map((pro, index) => (
                    <li key={pro}>
                      <img className="offer-icon" src={Check} alt="" />
                      {pro}
                    </li>
                  ))}
                {training.cons &&
                  training.cons.map((con, index) => (
                    <li key={index}>
                      <img className="offer-icon" src={Cross} alt="" />
                      {con}
                    </li>
                  ))}
              </ul>
              <div className="offer-button">
              <a href={`/products/${training.shopifyId}`}>
                  <button
                    className={`${
                      index === 0
                        ? "button button-offer"
                        : "button button-outline button-outline-offer button-offer"
                    }`}
                  >
                    {training.title === "Trening personalny" ||
                    training.title === "Prowadzenie Online 1:1"
                      ? "sprawdź"
                      : "spróbuj plan"}
                  </button>
                </a>
              </div>
            </div>
          </div>
        ))}
        {/* <Form isVisible={formVisible} onClose={() => setFormVisible(false)} /> */}
      </div>
    </div>
  );
});

export default Offer;
