//import ReactDOM from "react-dom/client";
import React, { useEffect, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
//import ProductPage from "./pages/ProductPage";
import Product from "./pages/Product";
import "./styles/App.css";
import "./styles/Fonts.css";
import "./styles/button-styles.css";
import "./styles/text-styles.css";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import NotFoundPage from "./components/Law/NotFound";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import CookieConsent from "react-cookie-consent";

function App() {
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem("cookieConsent") === "true";
    setConsent(consentGiven);

    if (consent && process.env.NODE_ENV === "production") {
      initializeAnalytics();
    }
  }, [consent]);

  const initializeAnalytics = () => {
    clarity.init(process.env.REACT_APP_CLARITY_ID);
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
    });
    const options = {
      autoConfig: true,
      debug: false,
    };
    if (!window.fbq) {
      ReactPixel.init(process.env.REACT_APP_PIXEL_ID, null, options);
      ReactPixel.pageView();
    }
  };

  const handleAcceptCookie = () => {
    localStorage.setItem("cookieConsent", "true");
    setConsent(true);
    initializeAnalytics();
  };

  return (
    <>
      <CookieConsent
        enableDeclineButton
        declineButtonText="Nie Akceptuję"
        style={{
          background: "rgba(184, 87, 217, 1)",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
        buttonStyle={{ background: "white" }}
        declineButtonStyle={{ background: "white", color: "black" }}
        onAccept={handleAcceptCookie}
        buttonText="Akceptuję"
      >
        Ta strona korzysta z plików cookie i narzędzi analitycznych...
      </CookieConsent>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="products/:productId" element={<Product />} />
          <Route path="privacy-policy" element={<PrivacyPage />} />
          <Route path="terms-of-service" element={<TermsPage />} />
          <Route path="*" element={<NotFoundPage />} />
          {/* <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
           */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
