import React from "react";
import { forwardRef } from "react";
import "./Contact.css";
import IgaTlo from "../../images/iga-tlo.webp";

const Contact = forwardRef((props, ref) => {
  return (
    <div className="ask">
      <div className="ask-content">
        <div className="ask-content-header" ref={ref}>
          <h1>ZAPYTAJ MNIE O CO CHCESZ</h1>
          <p>Odpowiadam, motywuję, inspiruję.</p>
        </div>
        <div className="ask-content-body">
          <div className="ask-content-body-photo">
            <img src={IgaTlo} width="1080" height="1080" alt="Iga Sokołowska" />
            <div className="ask-content-body-photo-title">
              <h2>Iga Sokołowska</h2>
              <p>Trener personalny Online</p>
            </div>
          </div>
          <div className="ask-content-body-text">
            <p>
              "Chętnie dzielę się wiedzą i doświadczeniem, aby pomóc innym
              kobietom we właściwym kierowaniu swoimi treningami. Sprawdź moje
              plany i pytaj śmiało! Zyskaj jasność i kierunek swojej podróży w
              kształtowaniu sylwetki.”
            </p>
            <a href="https://www.instagram.com/iga_sokolowska/">
              Napisz do mnie
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Contact;
