import React from "react";
import "./GoBackButton.css";

export default function GoBackButton() {
  return (
    <div className="go-back-container">
      <a href="/">
        <button className="go-back-button">Wróć</button>
      </a>
    </div>
  );
}
