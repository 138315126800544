import React from "react";
import Navbar from "../components/Navbar/Navbar";
import PrivacyPolicy from "../components/Law/PrivacyPolicy";

export default function PrivacyPage() {
  return (
    <div>
      <Navbar goBackButton={true} />
      <PrivacyPolicy />
    </div>
  );
}
