import React from "react";
import "./BelowHero.css";

export default function BelowHero() {
  return (
    <div className="below-hero">
      <div className="below-hero-text">
        <h1>
          Pomagam Kobietom w walce o lepszą wersję siebie i pokazuję, że nie
          jest to takie trudne, jak im się wydaje. Chciałabyś odzyskać formę po
          ciąży, schudnąć, zbudować mięśnie lub po prostu coś zmienić?
          Skorzystaj z jednej z proponowanych przeze mnie opcji współpracy, a z
          przyjemnością pomogę Ci osiągnąć Twój cel!
        </h1>
      </div>
    </div>
  );
}
