import React, { useRef } from "react";
import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/Hero";
import BelowHero from "../components/BelowHero/BelowHero";
import Services from "../components/Services/Services";
import Offer from "../components/Offer/Offer";
import Testimonials from "../components/Testimonials/Testimonials";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contact/Contact";
import ShopifyBuyButton from "../components/Product/ShopifyBuyButton";

export default function Home() {
  const offerRef = useRef();
  const contactRef = useRef();

  const scrollToOffer = () => {
    if (offerRef.current) {
      offerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <ShopifyBuyButton />
      <Navbar goBackButton={false} />
      <Hero scrollToOffer={scrollToOffer} scrollToContact={scrollToContact} />
      <BelowHero />
      <Offer ref={offerRef} />
      <Services />
      <Contact ref={contactRef} />
      <Testimonials />
      <Footer />
    </div>
  );
}
