import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="not-found-container">
      <div className="not-found-container-text">
        <h1>404</h1>
        <p>Strona nie znaleziona</p>
        <p>Za chwilę zostaniesz przekierowany na stronę główną...</p>
      </div>
    </div>
  );
};

export default NotFoundPage;
