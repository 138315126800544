import React, { useEffect } from "react";
import "./ProductContainer.css";

const ShopifyBuyButton = ({ productId }) => {
  useEffect(() => {
    const loadShopifyScript = () => {
      const scriptURL =
        "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
      const script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      script.onload = () => {
        initShopifyBuyButton(); // Inicjalizuje przycisk kupna po załadowaniu skryptu
      };
      document.head.appendChild(script);
    };

    // Inicjalizuje przycisk kupowania Shopify
    const initShopifyBuyButton = () => {
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          buildShopifyButton();
        } else {
          window.ShopifyBuyInit = buildShopifyButton;
        }
      } else {
        window.ShopifyBuyInit = buildShopifyButton;
        loadShopifyScript();
      }
    };

    const buildShopifyButton = () => {
      const client = window.ShopifyBuy.buildClient({
        domain: process.env.REACT_APP_SHOPIFY_DOMAIN,
        storefrontAccessToken: process.env.REACT_APP_STOREFRONT_ACCESS_TOKEN,
      });

      window.ShopifyBuy.UI.onReady(client).then((ui) => {
        ui.createComponent("product", {
          id: productId,
          node: document.getElementById(
            `product-component-${process.env.REACT_APP_PRODUCT_COMPONENT}`
          ),
          moneyFormat: "%7B%7Bamount_with_comma_separator%7D%7D%20z%C5%82",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0",
                    "margin-bottom": "50px",
                  },
                  "text-align": "left",
                  marginBottom: "50px",
                },
                title: {
                  "font-size": "25px",
                },
                button: {
                  ":hover": {
                    "background-color": "black",
                  },
                  "border-radius": "20px",
                  "background-color": "black",
                  "font-weight": "600",
                  background:
                    "linear-gradient(135deg, #b857d9 0%, #e3b6f2 100%)",
                  "box-shadow": "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

                  ":focus": {
                    "background-color": "black",
                  },
                },
                price: {
                  "font-size": "20px",
                },
                compareAt: {
                  "font-size": "15.299999999999999px",
                },
                unitPrice: {
                  "font-size": "15.299999999999999px",
                },
                img: {
                  "border-radius": "20px",
                },
                description: {
                  "font-weight": "700",
                },
              },
              layout: "horizontal",
              contents: {
                img: false,
                imgWithCarousel: true,
                description: true,
              },
              width: "100%",
              text: {
                button: "Dodaj do koszyka",
              },
            },
            productSet: {
              styles: {
                products: {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px",
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px",
                  },
                },
                button: {
                  ":hover": {
                    "background-color": "#8e4ea4",
                  },
                  background:
                    "linear-gradient(135deg, #b857d9 0%, #e3b6f2 100%)",
                  ":focus": {
                    "background-color": "#8e4ea4",
                  },
                },
                title: {
                  "font-weight": "bold",
                  "font-size": "26px",
                  color: "#4c4c4c",
                },
                price: {
                  "font-weight": "normal",
                  "font-size": "18px",
                  color: "#4c4c4c",
                },
                compareAt: {
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
                unitPrice: {
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
              },
              text: {
                button: "Dodaj do koszyka",
              },
            },
            option: {},
            cart: {
              styles: {
                button: {
                  ":hover": {
                    "background-color": "#8e4e4",
                  },
                  background:
                    "linear-gradient(135deg, #b857d9 0%, #e3b6f2 100%)",
                  ":focus": {
                    "background-color": "#8e4ea4",
                  },
                },
              },
              text: {
                title: "Koszyk",
                total: "Suma",
                empty: "Twój koszyk jest pusty",
                notice:
                  "Kody promocyjne są naliczane podczas realizacji zakupu.",
                button: "Podsumowanie",
              },
            },
            toggle: {
              styles: {
                toggle: {
                  background:
                    "linear-gradient(135deg, #b857d9 0%, #e3b6f2 100%)",
                  ":hover": {
                    "background-color": "#8e4ea4",
                  },
                  ":focus": {
                    "background-color": "#8e4ea4",
                  },
                },
              },
            },
          },
        });
      });
    };

    // Wywołuje inicjalizację przycisku kupowania po załadowaniu komponentu
    initShopifyBuyButton();

    // Opcjonalnie: Czyszczenie skryptów po odmontowaniu komponentu
    return () => {
      const scriptTags = document.querySelectorAll(
        'script[src^="https://sdks.shopifycdn.com"]'
      );
      scriptTags.forEach((script) => script.remove());
    };
  }, [productId]);

  return (
    <div
      className="product"
      id={`product-component-${process.env.REACT_APP_PRODUCT_COMPONENT}`}
    ></div>
  );
};

export default ShopifyBuyButton;
