import React from "react";
import "./Navbar.css";
import IgaTlo from "../../images/iga-tlo.webp";
import GoBackButton from "../GoBackButton/GoBackButton";

export default function Navbar({ goBackButton }) {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggleMenu = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <>
      <div>
        <nav className="navbar">
          <a className="logo" href="/">
            <img src={IgaTlo} alt="Iga Sokołowska" />
            <div className="nav-title">
              <span className="bigger-title">Iga Sokołowska</span>
              <span className="smaller-title">
                Certyfikowany trener personalny
              </span>
            </div>
          </a>
          <ul className="nav-links">{goBackButton && <GoBackButton />}</ul>
        </nav>
      </div>
    </>
  );
}
