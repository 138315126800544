import React from "react";
import "./Law.css";

const TermsOfService = () => {
  return (
    <div className="law-container">
      <div className="law-container-text">
        <h1>REGULAMIN</h1>
        <p>Regulamin korzystania ze strony internetowej igasokolowska.pl</p>

        <h2>1. Akceptacja Regulaminu</h2>
        <p>
          Korzystając z tej strony internetowej, użytkownik zgadza się na
          przestrzeganie wszystkich zasad i warunków zawartych w tym
          Regulaminie. Korzystanie z tej strony jest równoznaczne z akceptacją
          Regulaminu w całości.
        </p>

        <h2>2. Usługi Oferowane</h2>
        <p>
          Iga Sokołowska - Trener Personalny Online - oferuje usługi treningowe,
          w tym sprzedaż Planów Treningowych przez stronę internetową. Plan
          treningowy można zakupić online poprzez platformę dostępną na tej
          stronie.
        </p>

        <h2>3. Warunki Zakupu</h2>
        <p>
          a. Zakup Planu Treningowego od Iga Sokołowska - Trener Personalny
          Online - odbywa się poprzez platformę internetową dostępną na stronie.
          Użytkownik może wybrać odpowiedni plan treningowy, dokonać płatności
          online i uzyskać dostęp do zakupionego planu.
        </p>
        <p>
          b. Wszelkie informacje dotyczące cen, zakresu usług oraz warunków
          zakupu są podane na stronie internetowej.
        </p>

        <h2>4. Warunki Użytkowania</h2>
        <p>
          a. Użytkownik zobowiązuje się do korzystania ze strony internetowej w
          sposób zgodny z prawem oraz z poszanowaniem zasad etyki i dobrych
          obyczajów.
        </p>
        <p>
          b. Zabrania się jakiegokolwiek naruszania bezpieczeństwa strony, prób
          dostępu do danych osobowych innych użytkowników lub zmian w działaniu
          strony bez zgody właściciela.
        </p>

        <h2>5. Płatności</h2>
        <p>
          a. Wszelkie płatności za zakupione usługi odbywają się online za
          pośrednictwem przelewu bankowego (BLIK/ Revolut lub przez Stripe lub
          Przelewy24 - Skrill).
        </p>

        <h2>6. Prawa Autorskie</h2>
        <p>
          a. Wszelkie treści, materiały i plany treningowe udostępnione na tej
          stronie internetowej są własnością Trenera Personalnego Online i
          objęte prawami autorskimi.
        </p>

        <h2>7. Odpowiedzialność</h2>
        <p>
          Właściciel Serwisu nie ponosi odpowiedzialności za szkody wynikłe z
          korzystania lub niemożności korzystania ze strony, ani za jakiekolwiek
          problemy zdrowotne użytkowników wynikłe z zastosowania planów
          treningowych. Użytkownik korzysta z usług na własne ryzyko.
        </p>

        <h2>8. Zmiany w Regulaminie</h2>
        <p>
          Iga Sokołowska - Trener Personalny Online - zastrzega sobie prawo do
          zmiany Regulaminu. Użytkownicy zostaną poinformowani o wszelkich
          istotnych zmianach poprzez umieszczenie informacji na stronie.
        </p>

        <h2>9. Kontakt</h2>
        <p>
          W przypadku pytań lub wątpliwości dotyczących Regulaminu lub usług
          oferowanych na tej stronie, użytkownicy mogą skontaktować się z Iga
          Sokołowska - Trener Personalny Online - za pośrednictwem danych
          kontaktowych podanych na stronie.
        </p>

        <p>
          SICHNELL sp. z o.o. Tomasz Ostrowski Ul. Jasna 2 57-215 Srebrna Góra
          NIP: 7011008233 Regon: 387703813
        </p>

        <p>
          Korzystanie ze strony oznacza akceptację wszystkich warunków zawartych
          w tym Regulaminie. Prosimy o zapoznanie się z nim przed skorzystaniem
          z usług.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
